<template>
  <div id="main">
    <BCard>
      <BRow class="pl-1 mb-3">
        <span class="title text-black"> Monitoring Order </span>
      </BRow>

      <BRow class="mb-2">
        <div class="badge p-1 ml-1">
          <span> Paksa Delivered </span>
        </div>
      </BRow>

      <BRow class="justify-content-end mr-2 mb-2">
        <div
          class="dropdown-shipment d-flex justify-content-between align-items-center p-1 mr-1 mb-1"
          data-toggle="dropdown"
        >
          <span class="text-black"> Ekspedisi </span>
          <BImg
            src="https://storage.googleapis.com/komerce/assets/icons/arrow-down-light.svg"
          />
        </div>
        <div
          class="dropdown-menu dropdown-menu-shipment mr-1"
          @click="onCLoseDropdownShipment($event)"
        >
          <div
            class="p-50"
          >
            <b-form-checkbox
              v-for="(item, idx) in shippingItems"
              :id="item"
              :key="idx"
              v-model="params.shipping"
              :class="idx === 0 ? '' : 'my-[5px]'"
              :value="item"
            >
              {{ item }}
            </b-form-checkbox>
          </div>
        </div>

        <DateRangePicker
          ref="picker"
          v-model="dateRange"
          :locale-data="locale"
          :ranges="ranges"
          opens="center"
          control-container-class="periode-datepicker"
          :date-format="dateFormat"
        >
          <template v-slot:input>
            <div
              class="dropdown-date-range d-flex justify-content-between align-items-center p-1 mr-1"
            >
              <span class="text-black"> Range Waktu </span>
              <BImg
                src="https://storage.googleapis.com/komerce/assets/icons/arrow-down-light.svg"
              />
            </div>
          </template>

          <div slot="footer">
            <div class="d-flex justify-content-end m-1">
              <BButton
                variant="outline-primary"
                class="btn-icon mr-1"
                size="sm"
                style="letter-spacing: 1px"
                @click="hideDateRanges"
              >
                <strong> Kembali </strong>
              </BButton>
              <BButton
                variant="primary"
                class="btn-icon"
                size="sm"
                style="letter-spacing: 1px"
                @click="applyDateRange"
              >
                <strong> Submit </strong>
              </BButton>
            </div>
          </div>
        </DateRangePicker>

        <div class="input-group wrapper-search-cnote mb-1">
          <div class="input-group-prepend">
            <span
              class="input-group-text pr-0"
              style="border: 0; border-radius: 8px"
            >
              <img
                src="https://storage.googleapis.com/komerce/assets/icons/search-abu.svg"
                width="24"
              >
            </span>
          </div>
          <input
            v-model="cnote"
            type="text"
            class="form-control"
            placeholder="Cari nomor resi"
            style="border: 0; border-radius: 8px"
            @input="search"
          >
        </div>
      </BRow>

      <div class="table-responsive">
        <table class="table table-borderless">
          <thead class="custom-thead-class">
            <tr>
              <th
                scope="col"
                class="th-custom"
              >
                Tanggal Order
              </th>
              <th
                scope="col"
                class="th-custom"
              >
                Pelanggan
              </th>
              <th
                scope="col"
                class="th-custom"
              >
                Produk
              </th>
              <th
                scope="col"
                class="th-custom"
              >
                Total Pembayaran
              </th>
              <th
                scope="col"
                class="th-custom"
              >
                NO Resi
              </th>
              <th
                scope="col"
                class="th-custom"
              >
                Bukti Validasi
              </th>
            </tr>
          </thead>
          <tbody v-if="listOrder.length > 0">
            <tr
              v-for="(item, index) in listOrder"
              :key="index"
            >
              <td>
                <div>
                  <span class="text-black"> {{ formatOnlyDate(item.order_date) }} </span>
                </div>
                <div>
                  <small class="text-muted"> {{ formatHours(item.order_date) }} </small>
                </div>
                <div class="d-flex align-items-center">
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/house-2.svg"
                    width="14"
                    class="mr-50"
                  >
                  <small class="text-muted">{{ item.partner_address }}</small>
                </div>
              </td>

            <td>
              <div class="mb-1">
                <span class="text-black"> {{ item.customer_name }} </span>
              </div>
              <div class="d-flex align-items-center">
                <img
                  :src="item.shipping_image_path"
                  class="mr-1"
                  width="45"
                />
                <span class="text-black"> {{ item.shipping_alias }} </span>
              </div>
            </td>

              <td>
                <BRow
                  v-for="(productItem, productIndex) in item.products.slice(0, 1)"
                  :key="productIndex"
                  class="justify-content-between mb-1"
                >
                  <div class="d-flex justify-content-evenly">
                    <div class="mr-50">
                      <img
                        :src="productItem.product_image ? productItem.product_image : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                        width="60"
                        height="60"
                      >
                    </div>
                    <div
                      cols="8"
                      class="d-flex flex-column justify-content-between align-items-start"
                    >
                      <p class="text-black mb-0">
                        {{ productItem.product_name }}
                      </p>
                      <small class="text-primary"> {{ productItem.variant_name }} </small>
                    </div>
                  </div>
                  <div class="text-end">
                    <span class="text-black"> x{{ productItem.qty }} </span>
                  </div>
                </BRow>
                <div class="text-right">
                  <b-collapse :id="`collapse-product-${String(index)}`">
                    <BRow
                      v-for="(productItem, productIndex) in item.products.slice(1, item.products.length)"
                      :key="productIndex"
                      class="justify-content-between mb-1"
                    >
                      <div class="d-flex justify-content-evenly">
                        <div class="mr-50">
                          <img
                            :src="productItem.product_image ? productItem.product_image : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                            width="60"
                            height="60"
                          >
                        </div>
                        <div
                          cols="8"
                          class="d-flex flex-column justify-content-between align-items-start"
                        >
                          <p class="text-black mb-0">
                            {{ productItem.product_name }}
                          </p>
                          <small class="text-primary"> {{ productItem.variant_name }} </small>
                        </div>
                      </div>
                      <div class="text-end">
                        <span class="text-black"> x{{ productItem.qty }} </span>
                      </div>
                    </BRow>
                  </b-collapse>
                  <BButton
                    v-if="item.products.length > 1"
                    v-b-toggle="`collapse-product-${String(index)}`"
                    class="p-0 d-flex align-items-center"
                    variant="flat-dark"
                  >
                    <small class="text-black mr-50">
                      Lihat Produk Lainnya
                    </small>
                    <img
                      class="when-close"
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
                      width="20"
                    >
                    <img
                      class="when-open"
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-up-2.svg"
                      width="20"
                    >
                  </BButton>
                </div>
              </td>

              <td>
                <BRow class="align-items-start">
                  <BCol cols="8">
                    <p class="text-black">
                      {{ IDR(item.grand_total) }}
                    </p>
                    <small class="text-primary"> {{ item.payment_method }} </small>
                  </BCol>
                  <BCol cols="4">
                    <BButton
                      :id="`order-notes-${index}`"
                      class="p-0"
                      variant="light"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/icons/info-order-notes.svg"
                        width="24"
                      >
                    </BButton>
                    <b-popover
                      :target="`order-notes-${index}`"
                      triggers="hover"
                      placement="top"
                    >
                      {{ item.order_notes ? item.order_notes : 'Tidak ada catatan.' }}
                    </b-popover>
                  </BCol>
                </BRow>
              </td>

              <td>
                <BRow class="align-items-center">
                  <BCol cols="8">
                    <span class="text-black"> {{ item.cnote }} </span>
                  </BCol>
                  <BCol cols="4">
                    <BButton
                      variant="flat-dark"
                      class="p-0"
                      @click="copyCnote(item.cnote)"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/copy.svg"
                        width="24"
                      >
                    </BButton>
                  </BCol>
                </BRow>
              </td>

              <td>
                <b-button
                  variant="flat-info"
                  @click="showAttachment(item)"
                >
                  <span> Lampiran </span>
                </b-button>
              </td>
            </tr>
          </tbody>
          <tbody
            v-else
            class="text-center"
          >
            <tr>
              <td
                colspan="6"
                class="text-black"
              >
                Tidak ada data untuk ditampilkan.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Modal Attachment -->
      <BModal
        ref="attachment-modal"
        centered
        no-close-on-backdrop
        no-close-on-esc
        hide-header
        hide-footer
      >
        <div
          v-if="loadingAttachment"
          class="d-flex justify-content-center m-2"
        >
          <BSpinner variant="primary" />
        </div>
        <div
          v-else
          class="my-3 mx-1"
        >
          <BRow class="text-center justify-content-center mb-2">
            <h2 class="text-black mb-2">
              <strong> Lampiran Bukti </strong>
            </h2>
            <span class="text-black">
              Lampirkan bukti pendukung bahwa paket tersebut memang sudah diterima
              Penerima
            </span>
          </BRow>

          <BRow class="mb-2">
            <BCol cols="4">
              <span class="text-black">Resi</span>
            </BCol>
            <BCol cols="8">
              <span class="text-black"> : {{ attachmentItem.cnote }} </span>
            </BCol>
          </BRow>

          <BRow class="mb-2">
            <BCol cols="4">
              <span class="text-black">Link Video Bukti</span>
            </BCol>
            <BCol
              cols="8"
              class="d-flex"
            >
              :
              <div
                v-if="attachmentItem.video_url !== '' && attachmentItem.video_url !== 'null'"
                class="cursor-pointer text-info ml-50"
                @click="openLink(attachmentItem.video_url)"
              >
                <span class="underline">{{ formatLink(attachmentItem.video_url) }}</span>
              </div>
              <div
                v-else
                class="ml-50"
              >
                -
              </div>
            </BCol>
          </BRow>

          <BRow class="mb-2">
            <BCol cols="4">
              <span class="text-black">Lampiran Bukti</span>
            </BCol>
            <BCol
              cols="8"
              class="d-flex"
            >
              <div class="mr-50">
                :
              </div>
              <div v-if="attachmentItem.image_urls !== null && attachmentItem.image_urls !== 'null' && attachmentItem.image_urls !== '' && attachmentItem.image_urls.length > 0">
                <div
                  v-for="(item, indexImg) in attachmentItem.image_urls"
                  :key="indexImg"
                  class="text-info cursor-pointer"
                  @click="openLink(item)"
                >
                  <span class="underline">{{ formatLink(item) }}</span>
                </div>
              </div>
              <div v-else>
                -
              </div>
            </BCol>
          </BRow>

          <BButton
            block
            variant="primary"
            style="border-radius: 8px; letter-spacing: 1px"
            @click="hideAttachment"
          >
            <strong> Tutup </strong>
          </BButton>
        </div>
      </BModal>
    </BCard>
  </div>
</template>

<script>
/* eslint-disable quote-props */
/* eslint-disable no-param-reassign */
import DateRangePicker from 'vue2-daterange-picker'
import { mapState } from 'vuex'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  today,
  last7,
  last30,
  firstDateOfMonth,
  firstDateOfMonthJuny,
  start3monthsAgo,
  tomorrow,
} from '@/store/helpers'
import moment from 'moment'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { IDR } from '@/libs/currency'

export default {
  components: { DateRangePicker },
  data() {
    return {
      loadingTable: false,
      isLastOrder: false,
      shippingItems: [],
      params: {
        startDate: moment(firstDateOfMonth).format('YYYY-MM-DD'),
        endDate: moment(today).format('YYYY-MM-DD'),
        offset: 0,
        limit: 25,
        shipping: [],
        q: '',
      },
      ranges: {
        'Semua ': [firstDateOfMonthJuny, today],
        'Hari ini': [today, today],
        '7 Hari Terakhir': [last7, today],
        '30 Hari Terakhir': [last30, today],
        'Bulan Ini': [firstDateOfMonth, today],
        'Custom': [firstDateOfMonth, firstDateOfMonth],
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Mei',
          'Jun',
          'Jul',
          'Agu',
          'Sep',
          'Okt',
          'Nov',
          'Des',
        ],
      },
      cnote: '',
      listOrder: [],
      loadingAttachment: false,
      attachmentItem: {
        cnote: '',
        video_url: '',
        image_urls: '',
      },
      IDR,
      visibleProduct: false,
    }
  },
  computed: {
    ...mapState('filter', ['expeditionList']),
  },
  watch: {
    dateRange() {
      this.params.offset = 0
      this.params.startDate = this.formatDate(this.dateRange.startDate)
      this.params.endDate = this.formatDate(this.dateRange.endDate)
      this.fetchData()
    },
    'params.shipping': {
      handler() {
        this.params.offset = 0
        this.fetchData()
      },
    },
  },
  created() {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('main').offsetHeight && !this.loadingTable) {
        this.nextData()
      }
    }
  },
  async mounted() {
    if (this.expeditionList.length === 0) {
      this.$store.dispatch('filter/getUpdateExpedition')
    }
    this.shippingItems = this.expeditionList.map(item => item.shipping_name)
    this.params.shipping = this.expeditionList.map(item => item.shipping_name)
    this.params.startDate = this.formatDate(start3monthsAgo)
    this.params.endDate = this.formatDate(this.params.endDate)
  },
  methods: {
    onCLoseDropdownShipment($event) {
      $event.stopPropagation()
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    formatOnlyDate(date) {
      return moment(date).format('DD-MM-YYYY')
    },
    formatHours(date) {
      return moment(date).format('HH:mm')
    },
    async showAttachment(data) {
      this.loadingAttachment = true
      await this.$store.dispatch('monitoringOrder/proofed', data.order_id).then(response => {
        this.loadingAttachment = false
        this.attachmentItem = response.data.data
      },
      err => {
        this.loadingAttachment = false
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: err,
            variant: 'danger',
          },
        }, 2000)
      })
      this.$refs['attachment-modal'].show()
    },
    hideAttachment() {
      this.$refs['attachment-modal'].hide()
    },
    hideDateRanges() {
      this.$refs.picker.open = false
    },
    applyDateRange() {
      this.$refs.picker.clickedApply()
    },
    async fetchData() {
      await this.$store.dispatch('monitoringOrder/list', this.params).then(response => {
        const { data } = response.data
        this.listOrder = data
        this.params.offset += data.length
        this.loadingTable = false
        if (data.length < this.params.limit) {
          this.isLastOrder = true
        } else {
          this.isLastOrder = false
        }
      },
      err => {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: err,
            variant: 'danger',
          },
        }, 2000)
      })
    },
    search: _.debounce(function () {
      this.params.q = this.cnote
      this.params.offset = 0
      this.fetchData()
    }, 1000),
    async nextData() {
      if (!this.isLastOrder) {
        this.loadingTable = true
        this.params.startDate = this.formatDate(this.params.startDate)
        this.params.endDate = this.formatDate(this.params.endDate)
        await this.$store.dispatch('monitoringOrder/list', this.params).then(response => {
          const { data } = response.data
          this.listOrder.push(...data)
          this.params.offset += data.length
          this.loadingTable = false
          if (data.length < this.params.limit) {
            this.isLastOrder = true
          } else {
            this.isLastOrder = false
          }
        },
        err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
        })
      }
    },
    formatLink(url) {
      let result
      if (url) {
        result = url.length > 30 ? `${url.split('/').pop().substring(0, 30)}...` : url.split('/').pop()
      }
      return result
    },
    openLink(url) {
      window.open(url, '_blank')
    },
    async copyCnote(cnote) {
      navigator.clipboard.writeText(cnote)
      this.$toast(
        {
          component: ToastificationContentVue,
          props: {
            title: 'Sukses',
            icon: 'CheckIcon',
            text: `Nomor resi ${cnote} berhasil di copy`,
            variant: 'success',
          },
        },
        2000,
      )
    },
    dateFormat(classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() < start3monthsAgo || date.getTime() > tomorrow
      }
      return classes
    },
  },
}
</script>

<style scoped>

* {
  font-family: "Poppins", sans-serif;
}
.title {
  font-size: 20px;
  font-weight: 600;
}

.badge {
  border-radius: 20px;
  background: #f95031;
  font-size: 16px;
  letter-spacing: 1px;
}

.dropdown-shipment,
.dropdown-date-range {
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  width: 175px;
  height: 40px;
  cursor: pointer;
}

.dropdown-menu-shipment {
  width: 174px;
}

.dropdown-item:hover {
  background: #fcd4be !important;
}

.wrapper-search-cnote {
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  width: 350px;
}

.custom-thead-class {
  background: #f4f4f4;
  height: 48px;
}

.th-custom {
  color: black;
  font-weight: 600;
  font-size: 14px;
  vertical-align: top;
}

::v-deep .custom-checkbox.custom-control:hover {
  background: #fcd4be;
  cursor: pointer!important;
}
::v-deep .custom-checkbox .custom-control-label {
  width: 100%!important;
  height: 30px!important;
  display: flex;
  align-items: center;
}

::v-deep .collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

::v-deep .not-collapsed > .when-close {
  display: none;
}
::v-deep .custom-checkbox .custom-control-label::before, ::v-deep .custom-checkbox .custom-control-label::after {
  top: 20%;
}
</style>
